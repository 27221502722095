//Angular modules
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../models/user';
import Swal from 'sweetalert2';

@Injectable()
export class AppService {
  // Controller
  private controller: AbortController = new AbortController();
  baseUrl = environment.apiUrl;
  private currentUserSource = new BehaviorSubject<User | null>(null);
  currentUser$ = this.currentUserSource.asObservable();

  constructor(private http: HttpClient, private router: Router) { }

  loadCurrentUser(token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);
    return this.http.get<User>(this.baseUrl + 'account', { headers }).pipe(
      map((user) => {
        localStorage.setItem('token', user.jwToken);
        this.currentUserSource.next(user);
      })
    );
  }

  login(email: string, password: string): Observable<User | false> {
    if (!email || !password) {
      return of(false);
    }

    return this.http
      .post<User>(`${this.baseUrl}Account/authenticate`, { email, password })
      .pipe(
        map((user) => {
          if (user) {
            localStorage.setItem('token', user.jwToken);
            localStorage.setItem('userId', user.id);
            this.currentUserSource.next(user);

            if (user.isVerified) {
              this.router.navigate(['home/home']);
            } else {
              this.router.navigateByUrl('/');
            }

            return user;
          } else {
            return false;
          }
        }),
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `${error.error.error}`;
    } else {
      // Server-side error
      errorMessage = `${error.error.error}`;
    }

    // Display the error message to the user
    Swal.fire({
      text: errorMessage,
      icon: 'warning',
      customClass: {
        confirmButton: 'btn btn-primary px-4',
      },
      buttonsStyling: false,
    });

    // Return an observable with a user-facing error message
    return throwError(() => new Error(errorMessage));
  }

  logout() {
    localStorage.removeItem('token');
    this.currentUserSource.next(null);
    this.router.navigateByUrl('/');
  }
}
